@font-face {
  font-family: "Liberty";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/907368/liberty.otf");
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

small {
  font-weight: 700;
  font-size: 10px;
  opacity: 0.7;
}

/* src/App.css */
body {
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(300deg, #160119, #1d0521, #010c17);
  background-size: 300% 300%;
  animation: gradient-animation 27s ease infinite;
  transition: 0.5s ease-in-out;
  position: relative;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: 960px) {
  body.modal-open {
    margin-left: 450px;
  }

  .modal-content {
    transform: perspective(900px) rotateY(33deg);
  }

  .modal-dialog {
    top: 50%;
    margin-left: 100px !important;
    transform: translateY(-50%) !important;
  }
}

.welcome-screen {
  width: 100%;
  height: 100dvh;
}
.welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.welcome img {
  height: 200px;
}

.title {
  font-weight: 800;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
}

.subtitle {
  font-weight: 800;
  font-size: 16px;
}

.title img {
  height: 150px;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 80px;
}

.card.card-custom {
  max-width: 620px;
  width: 100%;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff;
  background: #1a1d25;
  border-radius: 16px;
}

.card.card-custom .go-back {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 30px;
}

.card.card-custom .go-back:hover {
  background: rgba(255, 255, 255, 0.3);
}

.card.card-custom .scroll-to-bottom {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 30px;
}

.card.card-custom .scroll-to-bottom:hover {
  background: rgba(255, 255, 255, 0.3);
}

.card.card-custom .profile {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card.card-custom .profile .avatar {
  margin-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2px;
}

.card.card-custom .profile .avatar:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.card.card-custom .profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.card.card-custom .profile .thirsty-profile-coins {
  display: flex;
  font-size: 12px;
  font-weight: 800;
  align-items: center;
}

.card.card-custom .profile .thirsty-profile-coins img {
  width: 30px;
  height: 30px;
}
/* src/App.css */
.app-container {
  position: relative;
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.btn-login {
  background-color: #6441a4;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.btn-logout {
  background-color: #ff4d4d;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.list-container {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;
}

.list-container .no-entries {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 280px;
  width: 100%;
  font-weight: 800;
  opacity: 0.5;
}

.add-song {
  margin-top: 20px;
  margin-bottom: 10px;
}

.separator h4 {
  font-weight: 800;
  text-align: left;
}

.list-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.list-item-container span {
  margin-right: 15px;
  max-width: 25px;
  min-width: 25px;
}
.list-item {
  background: rgba(255, 255, 255, 0.1);
  height: 80px;
  line-height: 80px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  transition: transform 0.3s ease-in-out;
}

.list-item.selected {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 1px 1px 1px #121212;
}

.list-item:hover {
  background: rgba(255, 255, 255, 0.2);
}

.item-title {
  max-width: 280px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  text-align: left;
  flex: 0.8;
  max-height: 70px;
}

.item-thumbnail {
  width: 80px;
  height: 60px;
  flex: 0 0 80px;
  display: flex;
  align-items: center;
}

.item-thumbnail img {
  width: 100%;
  border-radius: 8px;
}

.item-controls {
  flex: 0 0 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-content: flex-start;
}

.main-container .item-controls div {
  align-self: center;
}

.item-controls svg.up {
  color: #489562;
}

.item-controls svg.down {
  color: #d2242a;
}
.item-controls svg.meh {
  color: transparent;
  visibility: hidden;
}

.item-controls div svg {
  border-radius: 4px;
  padding: 8px 6px;
  vertical-align: middle !important;
}

.item-controls div svg:hover:not(.up):not(.down) {
  background: rgba(255, 255, 255, 0.2);
}

.upvote {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upvote img {
  padding: 6px;
  box-sizing: content-box;
  border-radius: 4px;
}
.upvote img:hover {
  background: rgba(255, 255, 255, 0.2);
}

.item-share svg {
  border-radius: 4px;
  padding: 8px 6px;
  vertical-align: middle !important;
}

.item-share svg:hover {
  background: rgba(255, 255, 255, 0.2);
}

.item-duration {
  flex: 0.1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
}
.item-share {
  flex: 0.1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
  display: flex;
  justify-content: right;
}

button.modal-open-button {
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  cursor: pointer;
}

button.modal-open-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.not-on-mobile {
  display: flex;
}

@media screen and (max-width: 560px) {
  .not-on-mobile {
    display: none;
  }

  .item-controls {
    flex: 0 0 35px;
  }

  .title {
    margin-top: 50px;
  }
}

.thirsty-profile-name {
  font-weight: 800;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirsty-profile-name .name {
  margin-right: 25px;
}

.thirsty-profile-image img {
  width: 80px;
  height: 80px;
  border-radius: 80%;
  object-fit: cover;
  margin: 10px auto;
  overflow: hidden;
}

.coins {
  height: 20px;
  display: inline;
  vertical-align: middle;
  margin: 0 5px;
}

.neon-block .block:after {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
  opacity: 0.5;
  background: linear-gradient(45deg, #2e0665, #8c007e, #ff85d3);
  border-radius: 16px;
  animation: neon-block 10s linear infinite;
}

.neon-block .block .rainbow {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -2;
}
.neon-block .block .rainbow:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(315deg, #2e0665, #8c007e, #ff85d3);
}
.neon-block .block .rainbow:after {
  animation: neon-block 10s linear infinite;
}

@keyframes neon-block {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

/* MODAL CONTENT */

.modal.thirsty-modal {
  background-color: rgba(0, 0, 0, 0.84);
}

.modal.thirsty-modal .modal-content {
  background: #1a1d25;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.thirsty-modal .modal-header {
  background: transparent;
  background-color: transparent;
  color: #fff;
  border: 0;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.thirsty-modal .modal-header .btn-close {
  --bs-btn-close-color: #fff;
  color: #fff;
}

.thirsty-modal .modal-title {
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  text-transform: lowercase;
}

.thirsty-modal .modal-body {
  background: transparent;
  background-color: transparent;
  border: 0;
  color: #fff;
  padding: 20px;
}

.thirsty-modal .modal-body input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 0;
  border-radius: 9px;
  border: 0 !important;
  outline: 0 !important;
  padding: 5px 15px;
}

.thirsty-modal .modal-body input:focus {
  border: 0 !important;
  outline: 0 !important;
}

.thirsty-modal .modal-footer {
  background-color: transparent;
  background: transparent;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
}

.thirsty-modal .modal-footer button {
  color: #fff;
  font-weight: 800;
}

.thirsty-modal .thirsty-modal-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thirsty-modal .thirsty-modal-profile .thirsty-profile-image img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.thirsty-modal .thirsty-modal-profile {
  font-size: 12px;
}

.thirsty-modal small {
  font-size: 10px;
}

.video-preview {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.video-thumbnail {
  max-width: 150px;
  margin-right: 20px;
}

.video-thumbnail img {
  border-radius: 8px;
  width: 100%;
}

.video-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-details .datas {
  font-weight: 800;
  font-size: 14px;
  padding: 3px 7px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-right: 5px;
}

.rules-container {
  font-size: 14px;
  padding: 50px;
}

.rules-container h1 {
  font-size: 18px;
  font-weight: 800;
}

.rules-container h2 {
  font-size: 16px;
  font-weight: 800;
  text-align: left;
}

.rules-container li {
  text-align: left;
  margin: 10px 0;
}

.showcase-container {
  width: 100%;
  height: 100vh;
}

.showcase-container .item-share {
  align-items: center;
}

.showcase-container .vote-controls {
  margin-right: -30px;
}

.showcase-title {
  margin-top: 20px;
  font-size: 12px;
}

.showcase-coins {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
}

.showcase-container .card-container {
  margin: 0;
}

.showcase-container .card-custom .control-buttons {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  align-items: center;
}

.showcase-container .card-custom .control-buttons .back {
  margin-right: auto;
}

.showcase-container .card-custom .control-buttons .other-controls {
  margin-left: auto;
  display: flex;
}

.showcase-container .card-custom {
  max-height: 60%;
  height: 60vh;
  height: 60dvh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 0;
}

.showcase-container .player-container {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.showcase-container .list-item-container {
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 420px;
  transform: translate(-50%, -50%);
}

.showcase-container .list-item-container .item-controls {
  flex: 0 0 25px;
}

.showcase-container .list-item-container.above-entry {
  top: 10%;
}

.showcase-container .list-item-container.below-entry {
  transform: translate(-50%, +50%);
  bottom: 10%;
}

.showcase-container .list-item-container .list-item {
  background: #1a1a1a;
  color: #fff;
}

.showcase-container .list-item-container .list-item:hover {
  background: #1a1d25;
}

.showcase-container .loading {
  height: 100%;
  width: 100%;
  opacity: 0.7;
}
.showcase-container .vote-controls {
  display: flex;
}
.top-10.card-container {
  margin: 0;
}

.top-10 .item-title {
  width: 70%;
  max-width: 70%;
}

.top-10 .entry-rank {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
}

.screenshot-container {
  padding: 50px;
  background: linear-gradient(300deg, #160119, #1d0521, #010c17);
}

/* NEON TEST */

body .text-neon {
  font-family: "Liberty";
  font-weight: 100;
  font-size: 80px;
  letter-spacing: -20px;
  flex-flow: row;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
body .text-neon .letter {
  color: #d9fdff;
  text-shadow: 0 0 2rem #00f0ff;
  display: inline-block;
}
body .text-neon .letter.letter-2 {
  transform: translate(-0.2rem, 1rem) rotate(10deg);
  -webkit-animation: flicker 2s ease-in-out infinite alternate;
  animation: flicker 2s ease-in-out infinite alternate;
}

@-webkit-keyframes flicker {
  0% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  5.5% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  6% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  6.5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  8% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  50% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(13deg);
  }
  100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  5.5% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  6% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  6.5% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  8% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  50% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(13deg);
  }
  100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
}

/* NEON TExT */

.rules {
  position: absolute;
  padding: 6px 12px;
  right: 10px;
  top: 30px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 800;
  cursor: pointer;
}

.rules:hover {
  background: rgba(255, 255, 255, 0.1);
}

.controller {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  gap: 5px;
  font-size: 14px;
  z-index: 99;
  background: #1b1d25;
  border-radius: 12px;
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 10px 15px;
  align-items: stretch;
}

.controller .titles {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  text-align: left;
  padding: 10px;
}

.controller .titles .subtitle {
  margin-bottom: 0;
}

.controller div.add-song-button {
  align-content: center;
  flex-wrap: wrap;
  display: flex;
}

.controller .add-song-button {
  flex: 0.5;
  padding: 10px 0;
}

.controller .add-song {
  margin: 0;
  font-size: 12px;
  font-weight: 800;
  width: 100%;
  height: 100%;
}

.controller span.add-song {
  height: auto;
}

.controller .add-song svg {
  margin-right: 5px;
}
